<template>
  <!-- prettier-ignore -->
  <div class="basket">
    <div class="basket__header">
      <basic-close-button
        size="sm"
      />
    </div>

    <basic-scrolling-with-gradient>
      <div class="basket__content">
        <template v-if="hasProducts">
          <div class="basket__persons">
            <template
              v-for="person in persons"
              :key="person.personId"
            >
              <div class="basket__person person">
                <div class="person__head">
                  <person-name
                    class="title-3"
                    :person="person"
                  />

                  <basic-price
                    class="title-3"
                    :price="person.total"
                  />
                </div>

                <div
                  v-if="person.selectedProducts.length"
                  class="person__content"
                >
                  <template
                    v-for="product in person.selectedProducts"
                    :key="product.productId"
                  >
                    <div
                      class="person__product product"
                      :class="{'product__invalid': !product.valid }"
                    >
                      <div
                        class="body-3"
                        v-text="$t(`content.products.${product.productId}.name`)"
                      />

                      <div class="product__pricing">
                        <basic-product-pricing
                          :product="product"
                          size="sm"
                        />

                        <basic-product-price
                          class="body-3"
                          :product="product"
                          reduction-as-icon
                        />
                      </div>

                      <div class="product__action">
                        <template v-if="getProductState(person.partnerNumber, product.productId) === PRODUCT_STATE.EDITABLE">
                          <basic-text-button
                            class="product__remove"
                            @click="removeProduct(person.personId, product.productId)"
                          >
                            <template #leading>
                              <the-icon
                                art="solid"
                                name="trash-can-xmark"
                              />
                            </template>
                          </basic-text-button>
                        </template>
                      </div>
                    </div>
                    <template v-if="product.messages.length > 0">
                      <basic-message
                        v-for="message in product.messages"
                        :key="message.key"
                        :severity="message.type.toLowerCase()"
                        :content="$t(`error.${message.key}`)"
                        :actions="[{
                          label: $t('product.toOffer'),
                          callback: () => routeToTarget(person.personId, product.productId)
                        }]"
                      />
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>

        <template v-else>
          <div
            class="body-3"
            v-text="$t('basket.noProductsAddSome')"
          />
        </template>
      </div>
    </basic-scrolling-with-gradient>

    <div class="basket__footer">
      <basic-price
        v-show="hasProducts"
        price-class="h3"
        suffix-class="lead-2"
        :price="basketTotal"
        :suffix="$t('product.amountMonthly')"
      />

      <basic-button
        :label="persons.length > 0 ? $t('basket.gotoBasket') : $t('basket.gotoConfigurator')"
        @click="goto"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { events$, loading$ } from '@/services'
import { confirm } from '@/utils/Modal'

import basketStore from '@/store/basket'

import useApplication from '@/hooks/useApplication'
import useI18n from '@/hooks/useI18n'

import usePersonDetails from '@/hooks/usePersonDetails'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'
import useProductState from '@/hooks/useProductState'
import useStatus from '@/hooks/useStatus'

import { ReactivityUtil } from '@/utils/Reactivity'

import PersonName from '@/components/Person/PersonName'
import {
  BasicButton,
  BasicCloseButton,
  BasicMessage,
  BasicPrice,
  BasicProductPrice,
  BasicProductPricing,
  BasicScrollingWithGradient,
  BasicTextButton,
} from '@/components/Basic'

import { EVENT_PRODUCT, EVENT_TRACKING, SOURCE } from '@/config/events'
import { CHANNEL, PRODUCT_STATE, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { getDvpUrl, isWww } = useApplication()
const { selectedLanguage, t } = useI18n()
const { basketTotal, personsWithDetails } = usePersonDetails()
const { getSelectedProductsByCategory, removeProductFromPerson } = usePersonSpecificProduct()
const { getCategoryFromProduct, getCategoryIdFromProduct } = useProduct()
const { state } = useProductState()
const { basketHasProducts, isOnboardingSecured } = useStatus()

// INIT
const emit = defineEmits(['close'])
let route, router
if (!isWww.value) {
  route = useRoute()
  router = useRouter()
}

const props = defineProps({
  configuratorUrl: {
    type: String,
    default: null,
  },
})

// COMPUTED
const hasProducts = computed(() => {
  return basketHasProducts(personsWithDetails.value)
})

const persons = computed(() => {
  return personsWithDetails.value
    .filter(p => p.contractModified)
    .map(person => {
      const __person = ReactivityUtil.clone(person)

      __person.selectedProducts = getSelectedProductsByCategory(person.selectedProducts).reduce(
        (acc, value) => acc.concat(value.products),
        []
      )
      return __person
    })
})

// METHODS
function routeToTarget(personId, productId) {
  const categoryId = getCategoryIdFromProduct(productId)

  window.location.href = `${getDvpUrl(selectedLanguage.value)}/#/products/${personId}/${categoryId}`
}

async function askRemovalConfirmation(_payload) {
  const label = 'product.confirmRemoval'

  const secured = isOnboardingSecured.value
  return !secured || (await confirm({ label: t(label) }))
}

function getProductState(partnerNumber, productId) {
  return state(partnerNumber, productId)
}

function goto() {
  // in dvp
  if (route?.name) {
    router.push({ name: ROUTE_NAME.BASKET })

    // on dotcom
  } else {
    let url = props.configuratorUrl
    if (personsWithDetails.value.length && basketStore.basket.channel === CHANNEL.PRODUCTWIDGET) {
      events$.emit(EVENT_TRACKING.ENTERED, { source: CHANNEL.PRODUCTWIDGET })
      url += '#/basket'
    }
    window.location.href = url
  }

  emit('close')
}

async function removeProduct(personId, productId) {
  if (!(await askRemovalConfirmation(basketStore.basket))) {
    return
  }

  const categoryId = getCategoryFromProduct(productId)

  loading$.start()

  try {
    await removeProductFromPerson(personId, productId)
    loading$.end()

    events$.emit(EVENT_PRODUCT.REMOVED, {
      baset: basketStore.basket,
      categoryId,
      personId,
      productId,
      source: SOURCE.BASKET_WIDGET,
    })
  } catch (error) {
    console.log('error', error)
    loading$.failed()
  }
}
</script>

<style>
.basket {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--dotcom-responsive-gutter-responsive) 0;
  text-align: right;

  .basket__header {
    padding: 0 var(--dotcom-responsive-offset-responsive) var(--dotcom-responsive-spacing-res-xs);
  }

  .basket__content {
    display: flex;
    flex-direction: column;

    .basket__persons {
      display: flex;
      flex-direction: column;
      gap: var(--fixed-spacing-fix-04);
    }

    .body-3 {
      text-align: left;
    }
  }

  .person {
    display: flex;
    flex-direction: column;
    row-gap: var(--fixed-spacing-fix-04);
    padding: 0 0 var(--fixed-spacing-fix-06);

    .person__head {
      display: grid;
      grid-template-columns: 1fr 50px 28px;

      .person-name {
        justify-self: start;
      }
    }

    .person__content {
      display: flex;
      flex-direction: column;
      row-gap: var(--fixed-spacing-fix-02);
    }
  }

  .product {
    display: grid;
    grid-template-columns: 1fr 50px 28px;
    align-items: center;

    .product__pricing {
      display: flex;
      flex-direction: row;
      gap: var(--fixed-spacing-fix-03);
      justify-content: end;
    }

    .product__remove {
      cursor: pointer;
      background: none;
      border: none;
      font-size: 14px;
      color: var(--Interaction-States-enabled-default);
    }

    .product__invalid {
      color: var(--error);
    }
  }

  .basket__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: var(--fixed-spacing-fix-06);
    padding: var(--fixed-spacing-fix-02) var(--dotcom-responsive-offset-responsive) 0;
  }
}

:root .basket__widget .scrollable {
  padding: 0 var(--dotcom-responsive-offset-responsive);
  max-height: calc(100vh - 262px);
  height: calc(100vh - 60px);
}

@media (--v-medium) {
  :root .basket__widget {
    .basket__header {
      padding: 0 var(--fixed-spacing-fix-06) var(--fixed-spacing-fix-04);
    }
    .scrollable {
      padding: 0 var(--fixed-spacing-fix-06);
      max-height: auto;
      height: auto;
    }
    .basket__footer {
      row-gap: var(--fixed-spacing-fix-04);
      padding: var(--fixed-spacing-fix-02) var(--fixed-spacing-fix-06) 0;
    }
  }

  .basket {
    width: 396px;
    max-height: 85vh;
    padding: var(--fixed-spacing-fix-06) 0;
  }
}
</style>
