<template>
  <div
    v-if="$props.showCard"
    class="fab__card"
  >
    <template v-if="!contactInfo">
      <loading-alt />
    </template>

    <div
      v-if="isSmallOnlyViewport"
      class="fab__card-header"
    >
      <basic-close-button
        aria-label="Close Modal"
        size="sm"
      />
    </div>

    <div class="fab__card-title">
      <div
        class="title-1"
        v-text="$t('fab.title')"
      />
    </div>

    <basic-scrolling-with-gradient :has-gradient="isSmallOnlyViewport">
      <div class="fab__content">
        <!-- ALVA -->
        <div
          v-if="!alvaTmpDeactivated"
          class="fab__card-alva"
        >
          <fab-entry
            v-if="hasAssistanceAndAlva"
            :description="$t($props.entries.alva.description)"
            :links="$props.entries.alva.links"
            :title-label="$t($props.entries.alva.title)"
            @action="handleAction"
          />

          <div v-else-if="hasEntry(entryName.ALVA)">
            <div class="alva__text">
              <div v-text="$t('fab.entries.alva.bubble')" />
            </div>
            <div class="alva__action">
              <Avatar small />
              <basic-button
                class="alva__action__btn"
                hierarchy="primary"
                size="sm"
                :label="$t('fab.entries.alva.action')"
                @click="handleAlvaClick"
              />
            </div>
          </div>
        </div>

        <!-- CONTACT -->
        <div class="fab__card-contact">
          <fab-contact
            :title-label="$t($props.entries.contact.title)"
            @action="handleAction"
          />
        </div>

        <!-- APPOINTMENT -->
        <div
          v-if="hasEntry(entryName.APPOINTMENT)"
          class="fab__card-appointment"
        >
          <fab-entry
            :description="$t($props.entries.appointment.description)"
            :links="$props.entries.appointment.links"
            :title-label="$t($props.entries.appointment.title)"
            @action="handleAction"
          />
        </div>
      </div>
    </basic-scrolling-with-gradient>

    <div
      v-if="isSmallOnlyViewport"
      class="fab__card-close"
    >
      <basic-button
        :label="$t('form.close')"
        @click="handleModalClose(true)"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { events$ } from '@/services'

import useBrowser from '@/hooks/useBrowser'
import useContact from '@/hooks/useContact'
import useI18n from '@/hooks/useI18n'

import Avatar from '@/components/Aide/Avatar'
import { BasicButton, BasicCloseButton, BasicScrollingWithGradient } from '@/components/Basic'
import FabContact from '@/components/Fab/FabContact'
import FabEntry from '@/components/Fab/FabEntry'
import LoadingAlt from '@/components/Container/LoadingAlt'
import { fabActions, fabEntryName } from '@/components/Fab/entries'

import { EVENT_APPOINTMENT_MODAL_OPEN, EVENT_CHAT } from '@/config/events'

// HOOKS
const { isSmallOnlyViewport } = useBrowser()
const { contactInfo } = useContact()
const { t } = useI18n()

// INIT
const emit = defineEmits(['close'])
const props = defineProps({
  entries: {
    type: Object,
    default: () => {},
  },
  showCard: {
    type: Boolean,
    default: false,
  },
})

// DATA
const entryName = ref(fabEntryName)
const poorPeopleFab = ref(false)
const alvaTmpDeactivated = ref(true)

// COMPUTED
const hasAssistanceAndAlva = computed(() => {
  return isAssistanceAvailable.value && hasEntry(entryName.value.ALVA)
})

const isAssistanceAvailable = computed(() => {
  return contactInfo.value.chat || contactInfo.value.phone
})

// METHODS
function handleAction(action) {
  if (action?.name === fabActions.OPEN_CHAT) {
    events$.emit(EVENT_CHAT.OPEN)
  }

  if (action?.name === fabActions.CALENSO_ROUTE) {
    events$.emit(EVENT_APPOINTMENT_MODAL_OPEN, action.param)
  }

  handleModalClose(true)
}

function handleAlvaClick() {
  window.location = t(props.entries.alva.links[0].url)
  handleModalClose(true)
}

function handleModalClose(interacted) {
  emit('close', interacted)
}

function handleResize() {
  poorPeopleFab.value = isSmallScreen()
}

function hasEntry(__entryName) {
  return !!props.entries[__entryName]
}

function isSmallScreen() {
  return window.innerHeight < 750
}

// WATCHERS
watch(
  () => props.showCard,
  value => {
    if (!value) {
      poorPeopleFab.value = false
    } else {
      if (isSmallOnlyViewport.value) {
        poorPeopleFab.value = true
      }
    }
  },
  { immediate: true }
)

// LIFECYCLE HOOKS
onMounted(() => {
  window.addEventListener('resize', handleResize)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped>
.fab__card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fab__card-header {
  padding: 0 var(--dotcom-responsive-offset-responsive) var(--dotcom-responsive-spacing-res-xs);
}

.fab__card-title {
  padding: 0 var(--dotcom-responsive-offset-responsive) var(--dotcom-responsive-spacing-res-xxs);
}

.fab__card:deep(.scrollable) {
  padding: 0 var(--dotcom-responsive-offset-responsive);
  flex-grow: 1;
}

.fab__content {
  display: flex;
  flex-direction: column;
  row-gap: var(--fixed-spacing-fix-08);
}

.fab__card-close {
  padding: 0 var(--dotcom-responsive-offset-responsive);
}

@media (--v-medium) {
  .fab__card-title {
    padding: 0 var(--fixed-spacing-fix-06) var(--fixed-spacing-fix-08);
  }
  .fab__card:deep(.scrollable) {
    padding: 0 var(--fixed-spacing-fix-06);
  }
}
</style>
