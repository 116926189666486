<template>
  <label
    class="checkbox"
    :class="classes"
    @keydown.enter.prevent="handleChange"
  >
    <input
      ref="checkboxRef"
      v-bind="$attrs"
      class="checkbox__input outline"
      type="checkbox"
      tabindex="0"
      :name="name"
      :value="model"
      @change="handleChange"
      @blur="handleBlur"
    />

    <div class="checkbox__icon">
      <the-icon
        :art="iconData.art"
        :name="iconData.name"
      />
    </div>

    <slot name="label">
      <span
        class="checkbox__label"
        :class="$props.size === 'sm' ? 'body-2-expanded' : 'body-1'"
        v-html="label"
      />
    </slot>
  </label>
</template>

<script setup>
import { computed, ref } from 'vue'

// INIT
const model = defineModel({ type: Boolean })
const props = defineProps({
  label: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    required: true,
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },

  v: {
    type: Object,
    default: null,
  },
})

// DATA
const checkboxRef = ref()

// COMPUTED
const classes = computed(() => {
  return {
    [`checkbox--${props.size}`]: true,
    'checkbox--selected': model.value,
    'checkbox--error': isInvalid.value,
  }
})

function handleBlur() {
  checkboxRef.value?.classList.remove('has-outline')
}

const iconData = computed(() => {
  return {
    art: model.value === true ? 'solid' : 'regular',
    name: model.value === true ? 'square-check' : 'square',
  }
})

const isInvalid = computed(() => {
  return props.v?.$invalid && props.v?.$error
})

// METHODS
function handleChange() {
  model.value = !model.value
}
</script>

<style scoped>
.checkbox {
  --checkbox-c-icon: var(--Interaction-States-enabled-off);
  --checkbox-c-icon-hovered: var(--Interaction-States-enabled-off);
  --checkbox-c-icon-active: var(--Interaction-States-enabled-off);
  --checkbox-c-icon-disabled: var(--Interaction-States-disabled-default);
  --checkbox-c-label: var(--on-surface);
  --checkbox-c-label-disabled: var(--Interaction-States-disabled-default-label);
  --checkbox-c-state-layer-hovered: var(--Interaction-States-hovered-default-b);
  --checkbox-c-state-layer-active: var(--Interaction-States-pressed-default-c);

  display: flex;
  align-items: center;
  gap: var(--fixed-spacing-fix-03);
  position: relative;

  &--selected {
    --checkbox-c-icon: var(--Interaction-States-enabled-default);
    --checkbox-c-icon-hovered: var(--Interaction-States-hovered-default-a);
    --checkbox-c-icon-active: var(--Interaction-States-pressed-default-a);
    --checkbox-c-state-layer-hovered: var(--Interaction-States-hovered-default-b);
    --checkbox-c-state-layer-active: var(--Interaction-States-pressed-default-c);
  }

  &--error {
    --checkbox-c-icon: var(--error);
    --checkbox-c-icon-hovered: var(--error);
    --checkbox-c-icon-active: var(--error);
    --checkbox-c-state-layer-hovered: var(--Interaction-States-hovered-default-error);
    --checkbox-c-state-layer-active: var(--Interaction-States-pressed-default-error);
  }

  &--sm {
    --checkbox-icon-size: 20px;
  }

  &--md {
    --checkbox-icon-size: 24px;
  }

  &__input {
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }

  &__icon {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      height: 40px;
      width: 40px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      border-radius: 50%;
    }
  }

  &__label {
    color: var(--checkbox-c-label);
  }

  &:has(.checkbox__input.has-outline:focus-visible) .checkbox__icon {
    outline: 2px solid var(--on-surface);
    outline-offset: var(--fixed-spacing-fix-01);
    border-radius: var(--fixed-border-radius-fix-02);
  }

  &:deep(.icon) {
    font-size: var(--checkbox-icon-size);
    color: var(--checkbox-c-icon);
    position: relative;
    z-index: var(--dvp-stack-level-element);
  }

  &:hover {
    .checkbox__icon::after {
      background-color: var(--checkbox-c-state-layer-hovered);
    }

    &:deep(.icon) {
      color: var(--checkbox-c-icon-hovered);
    }
  }

  &:active {
    .checkbox__icon::after {
      background-color: var(--checkbox-c-state-layer-active);
    }

    &:deep(.icon) {
      color: var(--checkbox-c-icon-active);
    }
  }

  &[disabled] {
    .checkbox__icon::after {
      background-color: transparent;
    }

    &:deep(.icon) {
      color: var(--checkbox-c-icon-disabled);
    }

    .checkbox__label {
      color: var(--checkbox-c-label-disabled);
    }
  }
}
</style>
