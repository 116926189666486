<template>
  <!-- prettier-ignore -->
  <div
    class="pricing"
    :class="{ [`pricing--${props.size}`]: true }"
  >

    <template v-if="hasReduction">
      <div
        v-tooltip="reductionString"
        class="body-2 pricing__reduction"
        tabindex="0"
      >
        <template v-if="$props.size === 'md'">
          <span
            class="body-3"
            v-text="$t('product.reduction')"
          />
        </template>

        <the-icon
          art="light"
          name="badge-percent"
        />
      </div>
    </template>

    <template v-if="hasPenalty">
      <div
        v-tooltip="penaltyString"
        class="body-2 pricing__penalty"
        tabindex="0"
      >
        <template v-if="$props.size === 'md'">
          <span
            class="body-3"
            v-text="$t('product.penalty')"
          />
        </template>

        <the-icon
          art="light"
          name="badge-percent"
        />
      </div>
    </template>

  </div>
</template>

<script setup>
import { computed } from 'vue'

import useI18n from '@/hooks/useI18n'

// HOOKS
const { t } = useI18n()

// INIT
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },

  size: {
    type: String,
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'md',
  },
})

// COMPUTED
const reductions = computed(() => {
  return selectedOption.value.reductions?.filter(r => r.amount < 0) ?? []
})

const penalties = computed(() => {
  return selectedOption.value.reductions?.filter(r => r.amount > 0) ?? []
})

const hasReduction = computed(() => {
  return reductions.value.length > 0
})

const hasPenalty = computed(() => {
  return penalties.value.length > 0
})

const reduction = computed(() => {
  return {
    labels: reductions.value ?? [],
  }
})

const penalty = computed(() => {
  return {
    labels: penalties.value ?? [],
  }
})

const reductionString = computed(() => {
  return reduction.value.labels
    .map((r, index) => {
      return getReductionLabel(r, index)
    })
    .join('; ')
})

const penaltyString = computed(() => {
  return penalty.value.labels
    .map((r, index) => {
      return getReductionLabel(r, index)
    })
    .join('; ')
})

const selectedOption = computed(() => {
  return props.product.prices.find(p => p.selected) || {}
})

// METHODS
function getReductionLabel(__reduction, i) {
  const type = t(`info.${__reduction.type}`)
  const amount = Math.abs(__reduction.amount).toFixed(2)
  const separator = reduction.value.labels.length - 1 !== i ? ';' : ''

  return `${type} ${amount} ${t('product.currency')}${separator}`
}
</script>

<style scoped>
.pricing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 4px;
  line-height: initial;

  --Pricing-reduction-background: var(--colors-tertiary-900);
  --Pricing-reduction-color: var(--on-surface);

  --Pricing-penalty-background: var(--colors-warning-700);
  --Pricing-penalty-color: var(--on-warning-container);

  &__reduction,
  &__penalty {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
    border-radius: var(--radius-border-radius-05);
    height: 20px;
    padding: 0 var(--spacing-san-spacing-02);
    text-wrap: nowrap;
    line-height: initial;

    &:focus-visible {
      outline: 2px solid var(--lnk-c-outline);
      outline-offset: var(--fixed-spacing-fix-01);
      border-radius: var(--fixed-border-radius-fix-02);
    }
  }

  &__reduction {
    background: var(--Pricing-reduction-background);
    color: var(--Pricing-reduction-color);
  }

  &__penalty {
    background: var(--Pricing-penalty-background);
    color: var(--Pricing-penalty-color);
  }
}

.pricing--sm {
  .pricing__reduction,
  .pricing__penalty {
    padding: 0 var(--spacing-san-spacing-01);
  }
}
</style>
