<template>
  <!-- prettier-ignore -->
  <form
    ref="formRef"
    v-form-tracker="{triggerCancel, triggerInteraction}"
    novalidate
    autocomplete="off"
    :name="$props.name"
    @submit.prevent="debouncedSubmit"
  >
    <slot/>
  </form>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'

import debounce from 'lodash/debounce'

import events$ from '@/services/Events'
import vFormTracker from '@/directives/FormTracker'

import { DEFAULT_FOCUS_DELAY, DEFAULT_FORM_DEBOUNCE_TIME, FOCUSABLE_INPUT_QUERY } from '@/config/constants'
import { EVENT_FORM } from '@/config/events'

// INIT
const emit = defineEmits(['submit', 'cancel'])
const props = defineProps({
  autoFocus: {
    type: Boolean,
    default: true,
  },

  name: {
    type: String,
    required: true,
  },

  // trigger submit, even if form is not dirty
  noChangeDetection: {
    type: Boolean,
    default: false,
  },

  trackingDisabled: {
    type: Boolean,
    default: false,
  },

  validator: {
    type: Object,
    required: true,
  },
})

// DATA
const formRef = ref(undefined)
const debouncedSubmit = debounce(submit, DEFAULT_FORM_DEBOUNCE_TIME)

// COMPUTED
const payload = computed(() => {
  return {
    errors: yieldErrors(),
    form: props.name,
  }
})

// METHODS
function doAutofocus() {
  const firstElement = formRef.value?.querySelectorAll(FOCUSABLE_INPUT_QUERY)?.[0]

  if (firstElement) {
    window.setTimeout(() => {
      firstElement.focus()
    }, DEFAULT_FOCUS_DELAY)
  } else {
    window.setTimeout(() => {
      doAutofocus()
    }, 500)
  }
}

async function scrollToError() {
  await nextTick()

  const query = '.input--error, .select--error, .radio--error, .textarea--error, .checkbox--error, .toggle--error'

  // if not found withing form-bounds, search on root-level
  let element = formRef.value.querySelector(query)

  if (!element) {
    element = document.querySelector(query)
  }

  element?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}

async function submit() {
  // const isDirty = cloneDeep(props.validator.$anyDirty)

  const isValid = await props.validator.$validate()

  if (isValid) {
    if (!props.trackingDisabled) {
      events$.emit(EVENT_FORM.SUBMITTED, payload.value)
    }

    emit('submit')
  } else {
    await scrollToError()

    if (!props.trackingDisabled) {
      events$.emit(EVENT_FORM.ERROR, payload.value)
    }
  }
}

function triggerCancel() {
  if (!props.trackingDisabled) {
    events$.emit(EVENT_FORM.CANCELED, payload.value)
  }

  props.validator.$reset()
  emit('cancel')
}

function triggerInteraction(fieldName) {
  if (!props.trackingDisabled) {
    events$.emit(EVENT_FORM.INTERACTED, {
      ...payload.value,
      fieldName,
    })
  }
}

function yieldErrors() {
  if (props.validator.$errors?.length === 0) return ''

  return `${props.validator.$errors
    .map(err => {
      return `${err.$property}:${err.$validator}`
    })
    .join(',')};`
}

// LIFECYCLE HOOKS
onMounted(() => {
  if (!props.trackingDisabled) {
    events$.emit(EVENT_FORM.INIT, payload.value)
  }

  if (props.autoFocus) {
    doAutofocus()
  }
})

// EPILOGUE
defineExpose({ submit })
</script>
