import { events$ } from '@/services'
import { BasketUtil } from '@/utils/Basket'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import productStructureStore from '@/store/productStructure'

import useContract from '@/hooks/useContract'
import useI18n from '@/hooks/useI18n'
import useIncentive from '@/hooks/useIncentive'
import usePerson from '@/hooks/usePerson'
import usePersonDetails from '@/hooks/usePersonDetails'
import useSnackbar from '@/hooks/useSnackbar'

import { EVENT_BASKET, EVENT_PRODUCT, EVENT_URL } from '@/config/events'
import { ROUTE_NAME } from '@/config/constants'
import Router from '@/modules/Configurator/Router'

const routeExcludeList = [ROUTE_NAME.BASKET]

export function registerUpdateBasketListeners() {
  // HOOKS
  const { installContractOnUpdate } = useContract()
  const { installIncentiveOnUpdate } = useIncentive()
  const { getPerson } = usePerson()

  function getPartnerPayload() {
    const contractOwner = getPerson(basketStore.basket.contractOwner?.personId)
    const customerNumber = contractOwner?.contractNumber
      ? BasketUtil.getCustomerNumberFromContract(contractOwner.contractNumber)
      : null

    return {
      contractStartDate: basketStore.basket.contractStartDate,
      language: basketStore.basket.language,
      distributionPartnerNumber: partnerStore.basketDistributionPartnerNumber.value,
      collectiveNumber: basketStore.basket.collective?.contractNumber,
      customerNumber,
    }
  }

  events$.once(EVENT_BASKET.CREATED, () => {
    partnerStore.install(getPartnerPayload())
  })

  events$.on(EVENT_BASKET.UPDATED, ({ basket, originalBasket }) => {
    partnerStore.installOnUpdate(getPartnerPayload(), originalBasket)

    productStructureStore.installProductStructureOnUpdate(basket, originalBasket)

    installContractOnUpdate(basket)
    installIncentiveOnUpdate(basket, originalBasket)
  })
}

export function registerBasketActionListeners() {
  // HOOKS
  const { t } = useI18n()
  const { getPersonWithDetails } = usePersonDetails()
  const { addNotification } = useSnackbar()

  events$.on(EVENT_PRODUCT.ADDED, data => {
    if (data.silent) return

    const person = getPersonWithDetails(data.personId)
    const payload = {
      persistent: false,
      text: t('basket.events.add', {
        product: t(`content.products.${data.productId}.name`),
        firstName: person.firstName,
      }),
    }

    if (!routeExcludeList.includes(Router?.currentRoute.value?.name)) {
      payload.action = {
        label: t('basket.gotoBasket'),
        callback: () => {
          events$.emit(EVENT_URL.GOTO, {
            type: 'push',
            route: {
              name: ROUTE_NAME.BASKET,
              path: 'basket/',
            },
          })
        },
      }
    }

    addNotification(payload)
  })

  events$.on(EVENT_PRODUCT.REMOVED, data => {
    if (data.silent) return

    const person = getPersonWithDetails(data.personId)
    const payload = {
      persistent: false,
      text: t('basket.events.remove', {
        product: t(`content.products.${data.productId}.name`),
        firstName: person.firstName,
      }),
    }

    if (!routeExcludeList.includes(Router?.currentRoute.value?.name)) {
      payload.action = {
        label: t('basket.gotoBasket'),
        callback: () => {
          events$.emit(EVENT_URL.GOTO, {
            type: 'push',
            route: {
              name: ROUTE_NAME.BASKET,
              path: 'basket/',
            },
          })
        },
      }
    }

    addNotification(payload)
  })

  events$.on(EVENT_PRODUCT.CHANGED, data => {
    if (data.silent) return

    const person = getPersonWithDetails(data.personId)
    const payload = {
      persistent: false,
      text: t('basket.events.change', {
        product: t(`content.products.${data.productId}.name`),
        firstName: person.firstName,
      }),
    }

    if (!routeExcludeList.includes(Router?.currentRoute.value?.name)) {
      payload.action = {
        label: t('basket.gotoBasket'),
        callback: () => {
          events$.emit(EVENT_URL.GOTO, {
            type: 'push',
            route: {
              name: ROUTE_NAME.BASKET,
              path: 'basket/',
            },
          })
        },
      }
    }

    addNotification(payload)
  })
}
